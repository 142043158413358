import { Originator } from 'models';

export function formatPersonInfo(person) {
    if (person.personalInformation && Array.isArray(person.personalInformation.addresses)) {
        let mainAddress = person.personalInformation.addresses.find((address) => address.addressType === 'MAIN');
        person.deliveryAddress = person.personalInformation.addresses.find(
            (address) => address.addressType === 'POSTAL'
        );
        person.mainAddress = mainAddress;
        person.mainAddressStr = mainAddress ? `${mainAddress.street}, ${mainAddress.city}` : '';
        person.authorizedAddresses = person.personalInformation.addresses.filter((address) =>
            address.addressType.toLowerCase().includes('authorisedperson')
        );
    } else {
        // Handle the case where addresses are not available
        person.deliveryAddress = '';
        person.mainAddress = '';
        person.mainAddressStr = '';
        person.authorizedAddresses = [];
    }
    person.currentBalance = person.insurances && person.insurances.length > 0 ? person.insurances[0].runningBalance : 0;
    person.status = person.personalInformation ? person.personalInformation.active : null;

    return person;
}
export function formatOriginatorInfo(person) {
    let originator = new Originator();
    originator.personId = person.personId;

    let findOriginator = person.roles.find((role) => {
        if (role.foundationId && role.roleType) {
            return role.foundationId === 'VAK' && role.roleType === 'EMPLOYEE';
        }
    });

    originator = findOriginator;
    return originator;
}
